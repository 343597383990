import { get } from 'lodash'
import { uniqueId } from 'lodash'

import { DefaultCharacteristicInCreation } from '../../containers/MasterSpecificationDetailsContent/constants'
import {
  BaseSpecificationCharacteristic,
  IMasterSpecDetail,
  MasterSpecCharacteristic
} from '../../features/masterSpec/model'
import { convertBaseCharToMasterChar } from '../../pages/MasterSpecificationDetail/utils'

export const refactorCharacteristicForMasterCreation: (
  list: BaseSpecificationCharacteristic[],
  sectionId: string
) => Partial<MasterSpecCharacteristic>[] = (list, sectionId) => {
  if (!list) return []

  const filteredCharacteris =
    list?.filter(
      ({ characteristicDescription }) =>
        characteristicDescription?.includes(
          DefaultCharacteristicInCreation.TOTAL_CRITICAL_DEFECTS
        ) ||
        characteristicDescription?.includes(
          DefaultCharacteristicInCreation.TOTAL_MAJOR_DEFECTS
        ) ||
        characteristicDescription?.includes(
          DefaultCharacteristicInCreation.TOTAL_MINOR_DEFECTS
        )
    ) || []

  const refactoredList = filteredCharacteris.map((data) => {
    const newData = convertBaseCharToMasterChar(randomizeCharacteristicId(data))
    return { ...newData, qualityInspection: true, specificationSectionId: sectionId }
  })
  return refactoredList
}

export const cleanMasterSpec = (
  masterSpec: Partial<IMasterSpecDetail>,
  packageVariantId?: string
) => {
  if (masterSpec.masterSpecificationCharacteristics)
    return {
      id: get(masterSpec, 'id', '').length > 30 ? masterSpec.id : undefined,
      sideCollectorId: masterSpec.sideCollectorId,
      packageVariantId: packageVariantId || masterSpec.packageVariantId,
      thumbnailOverride: masterSpec.thumbnailOverride,
      thumbnailOverrideId: masterSpec.thumbnailOverrideId,
      supplierName: masterSpec.supplierName,
      supplierItemCode: masterSpec.supplierItemCode,
      title: masterSpec.title,
      description: masterSpec.description,
      status: masterSpec.status,
      masterCode: masterSpec.masterCode,
      customerProjectName: masterSpec.customerProjectName,
      nbShade: masterSpec.nbShade,
      remarks: masterSpec.remarks,

      masterSpecificationCharacteristics:
        masterSpec.masterSpecificationCharacteristics.map((characteristic) => {
          const charToClean = !characteristic.itemName
            ? convertBaseCharToMasterChar(characteristic)
            : characteristic
          const cleanChar = {
            id: get(charToClean, 'id', '').length > 30 ? charToClean.id : undefined,
            itemName: charToClean.itemName,
            overrideItemName: charToClean.overrideItemName,
            standard: charToClean.standard,
            overrideStandard: charToClean.overrideStandard,
            lowerLimit: parseFloat(charToClean.lowerLimit),
            upperLimit: parseFloat(charToClean.upperLimit),
            qualityInspection: charToClean.qualityInspection,
            masterHom: charToClean.masterHom,
            customizedDev: charToClean.customizedDev,
            supplierCoa: charToClean.supplierCoa,
            specificationSectionId: charToClean.specificationSectionId,
            baseSpecificationCharacteristicId:
              charToClean.baseSpecificationCharacteristicId,
            specificationCharacteristicImage: charToClean.specificationCharacteristicImage
          }
          return cleanChar
        })
    }
}
// filters array of objects, returning another array containing only the elements that match the filter
export const filterCharList = (charArray: any, filterObject: any) => {
  const result = charArray.filter((characteristic: any) =>
    Object.keys(filterObject).every((key) =>
      characteristic[key]?.toLowerCase().includes(filterObject[key].toLowerCase())
    )
  )
  return result
}

// NOTE - probably deprecated
export const filterCharListBySectionId = (charArray: any, charCodesToOmit: string[]) => {
  charArray.filter((characteristic: any) => {
    return charCodesToOmit.some((charCode) => charCode === characteristic['controlCode'])
  })
}

export const randomizeCharacteristicId = (characteristic: MasterSpecCharacteristic) => {
  const result = {
    ...characteristic,
    baseSpecificationCharacteristicId: characteristic.id,
    id: uniqueId()
  }
  return result
}
