import { FC, useEffect, useState } from 'react'

import { get } from 'lodash'

import { BoxWrapper } from '../BoxWrapper'
import { Divider } from '../Divider'
import InfoCell from '../InfoCell'
import { Text } from '../Text'

import { IImageInfo } from './types'

const ImageInfo: FC<IImageInfo> = ({
  data,
  fileKey,
  imageAlt,
  isEditing = false,
  isCreating = false,
  updateData = () => {}
}) => {
  const [isTouched, setIsTouched] = useState(false)

  const updatedHandler = (value: any) => {
    if (!isTouched) setIsTouched(true)
    updateData('image', { ...data.image, ...value })
  }

  useEffect(() => {
    if (!isEditing) {
      setIsTouched(false)
    }
  }, [isEditing])

  return (
    <>
      {isCreating && (
        <>
          <Text text="Image" variant="h3" />
          <Divider />
        </>
      )}
      <BoxWrapper enablePadding>
        <InfoCell
          value={get(data, 'image.value', '')}
          label={imageAlt}
          isEditing={isEditing}
          dataType="image"
          inputType="upload"
          inputConfig={{
            fileKey: fileKey,
            onChange: updatedHandler,
            isUrl: !isCreating && !isTouched
          }}
        />
      </BoxWrapper>
    </>
  )
}

export default ImageInfo
