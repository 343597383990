import {
  AZ_DBPRODOTTO_ADMIN,
  AZ_DBPRODOTTO_COMPATIBILITY_MANAGER,
  AZ_DBPRODOTTO_FS_READ,
  AZ_DBPRODOTTO_PACKAGING_CREATE,
  AZ_DBPRODOTTO_PACKAGING_EDIT,
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_DBPRODOTTO_PRODUCT_CREAT,
  AZ_DBPRODOTTO_PRODUCT_EDIT,
  AZ_DBPRODOTTO_PRODUCT_READ,
  AZ_DBPRODOTTO_REGULATORY_READ,
  AZ_DBPRODOTTO_REGULATORY_RW,
  AZ_DBPRODOTTO_SALES,
  AZ_DBPRODOTTO_STRATEGICMARKETING,
  AZ_DBPRODOTTO_TEST_MANAGER,
  AZ_DBS_SALA_CAMPIONI_AGREGATE,
  AZ_WIKIPACK_IE,
  AZ_WIKIPACK_MRKT,
  AZ_WIKIPACK_PKACCEVO,
  AZ_WIKIPACK_PKDEV,
  AZ_WIKIPACK_RDF,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_SRC_EDIT,
  AZ_WIKIPACK_TCS,
  SOURCING_CN_GROUP,
  SOURCING_EU_GROUP,
  SOURCING_US_GROUP
} from '@/constants/users'

import {
  TCheckPermissionFunc,
  TCheckUserByGoupListFunc,
  TCheckUserIdentityFunc
} from './types'

export const checkUserByGoupList: TCheckUserByGoupListFunc = ({
  userRoles,
  groupList
}) => {
  return groupList.every((role) => userRoles.includes(role))
}

export const isUserAdmin: TCheckUserIdentityFunc = ({ userRoles }) => {
  return userRoles.includes(AZ_DBPRODOTTO_ADMIN)
}

export const isUserPkDev: TCheckUserIdentityFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_WIKIPACK_PKDEV) &&
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ)
  )
}

export const isUserSourcing: TCheckUserIdentityFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_WIKIPACK_SRC) &&
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const isSalaCampioni: TCheckUserIdentityFunc = ({ userRoles }) => {
  return userRoles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE)
}

export const canUserSeeTargetPrice: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_WIKIPACK_PKACCEVO) ||
    userRoles.includes(AZ_WIKIPACK_MRKT) ||
    userRoles.includes(AZ_WIKIPACK_PKDEV) ||
    userRoles.includes(AZ_WIKIPACK_SRC) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeSupplierRef: TCheckPermissionFunc = ({ userRoles }) => {
  const usersNotAllowed = [
    AZ_DBPRODOTTO_SALES,
    AZ_DBPRODOTTO_STRATEGICMARKETING,
    AZ_WIKIPACK_PKACCEVO,
    AZ_WIKIPACK_IE,
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_MRKT,
    AZ_WIKIPACK_TCS
  ]

  const isVisible = !usersNotAllowed.some((role: string) => userRoles.includes(role))
  return isVisible
}

export const canUserSeeTestedSupplier: TCheckPermissionFunc = ({ userRoles }) => {
  const usersNotAllowed = [
    AZ_WIKIPACK_PKACCEVO,
    AZ_WIKIPACK_IE,
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_MRKT,
    AZ_WIKIPACK_TCS
  ]

  const isVisible = !usersNotAllowed.some((role: string) => userRoles.includes(role))
  return isVisible
}

export const canUserEditTargetPrice: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_WIKIPACK_SRC_EDIT) || userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeOrderCreatedBy: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    isUserAdmin({ userRoles }) ||
    isUserSourcing({ userRoles }) ||
    isUserPkDev({ userRoles })
  )
}

export const canUserSeePackageCreatedBy: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    isUserAdmin({ userRoles }) ||
    isUserSourcing({ userRoles }) ||
    isUserPkDev({ userRoles })
  )
}

export const canUserSeeTargetPriceUpdatedBy: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    isUserAdmin({ userRoles }) ||
    isUserSourcing({ userRoles }) ||
    isUserPkDev({ userRoles })
  )
}

export const canUserSeeEditbuttonInPackaging: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    isUserAdmin({ userRoles }) ||
    isUserSourcing({ userRoles }) ||
    userRoles.includes(AZ_WIKIPACK_SRC_EDIT) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canUserExportTargetPrice: TCheckPermissionFunc = ({ userRoles }) => {
  const isSourcingEU = checkUserByGoupList({ userRoles, groupList: SOURCING_EU_GROUP })
  const isSourcingCN = checkUserByGoupList({ userRoles, groupList: SOURCING_CN_GROUP })
  const isSourcingUS = checkUserByGoupList({ userRoles, groupList: SOURCING_US_GROUP })
  const isAdmin = isUserAdmin({ userRoles })
  return isSourcingEU || isSourcingCN || isSourcingUS || isAdmin
}

export const canUserReadPackagingData: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ)
  )
}

export const canUserAddAttachment: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canUserEditOtherTestedPackagingForFormulaSchema: TCheckPermissionFunc = ({
  userRoles
}) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_COMPATIBILITY_MANAGER) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserCanEditAttachment: TCheckPermissionFunc = ({ userRoles }) => {
  if (
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
    !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
    !userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  ) {
    return false
  }

  if (
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  ) {
    return true
  }
  return false
}

export const canUserEditPackaging: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canReadMasterSpecificationDetail: TCheckPermissionFunc = ({ userRoles }) => {
  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
      !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_DBPRODOTTO_ADMIN)) ||
    userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return false
  }

  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
      userRoles.includes(AZ_DBPRODOTTO_ADMIN)) &&
    !userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return true
  }
  return false
}

export const canUserAddMasterSpecification: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  )
}

export const canCopyOrDeletePackaging: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_CREATE)
  )
}

export const canReadProjectSpecificationDetail: TCheckPermissionFunc = ({
  userRoles
}) => {
  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
      !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_DBPRODOTTO_ADMIN)) ||
    userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return false
  }

  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
      userRoles.includes(AZ_DBPRODOTTO_ADMIN)) &&
    !userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return true
  }
  return false
}

export const canUserAddProjectSpecification: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  )
}

export const canUserEditTestedClaimForFormulaSchema: TCheckPermissionFunc = ({
  userRoles
}) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_TEST_MANAGER) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeOtherTestedPackagingForProduct: TCheckPermissionFunc = ({
  userRoles
}) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserExportPackagingSearchResult: TCheckPermissionFunc = ({
  userRoles
}) => {
  return userRoles.includes(AZ_DBPRODOTTO_ADMIN)
}

export const canUserAddVariant: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles?.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles?.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canUserReadFormulaSchemaData: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) || userRoles.includes(AZ_DBPRODOTTO_FS_READ)
  )
}

export const canUserSeeFschemaData: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_CREAT) ||
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT)
  )
}

export const canUserSeeTestedClaimForProduct: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserEditRegulatory: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
  )
}

export const canUserReadRegulatory: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
    userRoles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
  )
}

export const canUserSeeOtherTestedPackagingForFormulaSchema: TCheckPermissionFunc = ({
  userRoles
}) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_FS_READ) || userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeTestedClaimForFormulaSchema: TCheckPermissionFunc = ({
  userRoles
}) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_FS_READ) || userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserDeletePackaging: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canUserAddRelationships: TCheckPermissionFunc = ({ userRoles }) => {
  if (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  ) {
    return false
  }
  return true
}

export const canUserEditProduct: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT)
  )
}

export const canUserDeleteVariant: TCheckPermissionFunc = ({ userRoles }) => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_CREATE) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}
