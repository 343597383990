import { Form } from 'antd'
import { get } from 'lodash'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, InfoCell, Modal } from '../../../../components'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Divider } from '../../../../components/Divider'
import { Input } from '../../../../components/Form/Input'
import { Text } from '../../../../components/Text'

export const UpdateAttachmentModal = ({ record, flowName }: any) => {
  const {
    name,
    SAPCodes,
    description,
    id,
    attachmentContentTypeId,
    attachmentContentType
  } = record
  const initialState = {
    name,
    SAPCodes: [...(SAPCodes || [])],
    description
  }

  const dispatch = useDispatch()
  const [tempAttachment, setTempAttachment] = useState(initialState)
  const { id: packageId } = useParams()
  const handleChange = useCallback(
    (value: any, key: string) =>
      setTempAttachment((prevValue) => {
        return { ...prevValue, [key]: value }
      }),
    []
  )

  const handleSave = useCallback(() => {
    dispatch({
      type: 'packaging/uploadAttachment',
      payload: {
        record: {
          description: tempAttachment.description,
          SAPCodes: tempAttachment.SAPCodes,
          name: tempAttachment.name
        },
        flowName,
        attachmentContentTypeId,
        id,
        packageId
      }
    })
  }, [tempAttachment, attachmentContentTypeId, id, dispatch, flowName, packageId])

  return (
    <>
      <Modal
        modalKey={`edit-attachment-${id}`}
        btnIcon="EditOutlined"
        btnType="link"
        title="Update the attachment"
        size="medium"
        isSmall={true}
        centered
        btnClicked={() => setTempAttachment(initialState)}
        footer={[<Button key="save" label="Save" onClick={handleSave} />]}
      >
        <Form layout="vertical">
          <Divider />
          <BoxWrapper columnCount={2} enablePadding>
            <Input
              label="Name"
              onChange={(value: string) => handleChange(value, 'name')}
              value={get(tempAttachment, 'name', '')}
            />
            <Input
              label="Description"
              onChange={(value: string) => handleChange(value, 'description')}
              value={get(tempAttachment, 'description', '')}
            />
          </BoxWrapper>
          <BoxWrapper columnCount={2}>
            <InfoCell
              label="SAP Codes"
              dataType="tags"
              value={get(tempAttachment, 'SAPCodes', [])}
              inputType="input-tag"
              isEditing={true}
              inputConfig={{
                onChange: (value: any) => {
                  handleChange(value, 'SAPCodes')
                },
                disabled:
                  get(attachmentContentType, 'name', '').toLowerCase() !==
                  'project specification'
              }}
            />
            <div style={{ paddingTop: '25px', paddingLeft: '10px' }}>
              <Text
                text=""
                value="If you need to add SAP Codes, remember to press Enter on the keyboard"
              />
            </div>
          </BoxWrapper>
        </Form>
      </Modal>
    </>
  )
}
