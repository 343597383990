import { PayloadAction } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'
import { get } from 'lodash'
import { SagaIterator } from 'redux-saga'
import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { replaceObjectById } from '../../pages/MasterSpecCharacteristicDetail/utils'
import { masterSpecStatus } from '../../pages/MasterSpecificationDetail/constants'
import { convertImageDataToImage } from '../../pages/MasterSpecificationDetail/utils'
import { cleanProjectSpec } from '../../pages/ProjectSpecificationDetail/utils'
import {
  cleanMasterSpec,
  refactorCharacteristicForMasterCreation
} from '../../utils/masterAndProjSpecificationHelpers'
import { appSliceActions } from '../app'
import { PackagingDto } from '../packaging/model'
import { getPackageDetail } from '../packaging/selectors'

import { masterSpecActions } from './actions'
import {
  checkSapCodes,
  createMasterSpec,
  createProjectSpec,
  deleteMasterSpec,
  deleteProjectSpec,
  exportMasterSpec,
  exportProjectSpec,
  getMasterSpecDetail,
  getProjectSpecDetail,
  getSpecificationSections,
  searchCharacteristics,
  searchMasterSpec,
  searchProjectSpec,
  sendToSap,
  updateMasterSpec,
  updateProjectSpec,
  uploadCharImage,
  uploadMasterSpecThumbnail
} from './api'
import { masterSpecActionType } from './constants'
import {
  BaseSpecificationCharacteristic,
  IApplyAllSapCodeActionPayload,
  IBlobWithHeadersResp,
  IDeleteMasterSpecPayload,
  IExportMasterSpecificationPayload,
  IExportProjectSpecificationPayload,
  IFetchMasterSpecListPayload,
  IGetDetailPayload,
  IMasterSpec,
  IMasterSpecDetail,
  IProjectSpecDetail,
  MasterSpecification,
  SpecificationSection
} from './model'
import { masterSpecSliceActions } from './reducer'
import {
  selectAlertMessage,
  selectCharacteristicList,
  selectMasterSpecDetail,
  selectNewMasterSpec,
  selectNewProjectSpec,
  selectProjectSpecDetail,
  selectSpecificationSections
} from './selectors'

type IFetchListResp = SagaReturnType<typeof fetchMasterSpecList>
function* fetchMasterSpecList({ filters = {} }: IFetchMasterSpecListPayload) {
  try {
    const masterSpecList: IMasterSpec[] = yield call(searchMasterSpec, filters)
    return masterSpecList
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
    throw err
  }
}

function* fetchProjectSpecList({ filters = {} }: IFetchMasterSpecListPayload) {
  try {
    const masterSpecList: IMasterSpec[] = yield call(searchProjectSpec, filters)
    return masterSpecList
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
    throw err
  }
}

type IFetchCharListResp = SagaReturnType<typeof fetchCharList>
function* fetchCharList() {
  try {
    const charList: BaseSpecificationCharacteristic[] = yield call(searchCharacteristics)
    return charList
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
    throw err
  }
}

function* fetchMasterSpecsSaga({ payload }: PayloadAction<IFetchMasterSpecListPayload>) {
  const { filters, isAddNew } = payload
  if (!isAddNew) yield put(masterSpecSliceActions.setLoader(true))
  try {
    const list: IFetchListResp = yield call(fetchMasterSpecList, { filters })
    if (isAddNew) yield put(masterSpecSliceActions.setAddNewList(list))
    else yield put(masterSpecSliceActions.setList(list))
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* fetchProjectSpecsSaga({ payload }: PayloadAction<IFetchMasterSpecListPayload>) {
  const { filters } = payload
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const list: IFetchListResp = yield call(fetchProjectSpecList, { filters })
    yield put(masterSpecSliceActions.setProjectSpecificationList(list))
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* fetchCharSaga(): SagaIterator {
  try {
    const characteristicList: IFetchCharListResp = yield call(fetchCharList)
    yield put(masterSpecSliceActions.setCharacteristics(characteristicList))
  } catch (err: any) {
    throw err
  }
}

function* getAllCharSaga({ payload }: PayloadAction<{ hasLoader: boolean }>) {
  const { hasLoader } = payload || {}
  if (hasLoader) {
    yield put(masterSpecSliceActions.setLoader(true))
  }
  try {
    yield call(fetchCharSaga)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(masterSpecSliceActions.setLoader(false))
    }
  }
}

function* getCharAndApplyDefaultSaga() {
  yield put(masterSpecSliceActions.setLoader(true))

  try {
    yield call(getSpecificationSectionsSaga)
    yield call(fetchCharSaga)
    const specificationSections: SpecificationSection[] = yield select(
      selectSpecificationSections
    )

    const characteristicList: BaseSpecificationCharacteristic[] = yield select(
      selectCharacteristicList
    )

    const aestethicAndDecorationsId =
      specificationSections?.find((section: any) => section.code === 'A')?.id || ''

    const aesteticListForCreation = refactorCharacteristicForMasterCreation(
      characteristicList,
      aestethicAndDecorationsId
    )
    yield put(
      masterSpecSliceActions.updateNewMasterSpecification({
        key: 'masterSpecificationCharacteristics',
        value: aesteticListForCreation
      })
    )
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* createMasterSpecSaga({ payload }: PayloadAction<IFetchMasterSpecListPayload>) {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    yield call(createMasterSpec, payload)
    yield call(fetchMasterSpecList, {
      filters: { sideCollectorId: payload.filters?.sideCollectorId }
    })
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
    if (!get(payload, 'packageVariantId'))
      yield put({
        type: masterSpecActionType.MASTER_SPEC_FETCH_ALL,
        payload: {
          filters: {
            sideCollectorId: get(payload, 'sideCollectorId'),
            category: get(payload, 'category')
          }
        }
      })
  }
}

function* createProjectSpecSaga({ payload }: PayloadAction<any>) {
  const { data, callback } = payload
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    yield call(createProjectSpec, data)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
    yield put(masterSpecSliceActions.setAlert(err))
  } finally {
    const alert: Record<string, any> = yield select(selectAlertMessage)
    yield put({
      type: masterSpecActionType.PROJECT_SPEC_FETCH_ALL,
      payload: {
        filters: {
          sideCollectorId: get(data, 'sideCollectorId', ''),
          category: get(data, 'category')
        }
      }
    })
    yield put(masterSpecSliceActions.setLoader(false))
    if (!alert) callback()
  }
}

function* updateMasterSpecSaga({ payload }: PayloadAction<Partial<IMasterSpecDetail>>) {
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const originalMasterData: IMasterSpecDetail = yield select(selectMasterSpecDetail)
    const originalPackage: PackagingDto = yield select(getPackageDetail)

    const hadImage =
      !!get(originalMasterData, 'thumbnailOverride.url') ||
      !!get(originalPackage, 'image.value', '')

    if (
      (!payload.thumbnailOverride || payload.thumbnailOverride.createdAt) &&
      !hadImage
    ) {
      const updatedMasterSpec: IMasterSpecDetail = yield call(updateMasterSpec, payload)
      yield put(masterSpecSliceActions.setDetail(updatedMasterSpec))
    } else {
      const form_data = convertImageDataToImage(get(payload, 'thumbnailOverride', {}))
      const isImageDeleted = hadImage && !get(payload, 'thumbnailOverride.uid', '')
      let thumbnailImageId: string | null = null

      if (!isImageDeleted) {
        const response: { thumbnailImageId: string } = yield call(
          uploadMasterSpecThumbnail,
          form_data
        )
        thumbnailImageId = response.thumbnailImageId
      }
      const updatedPayload: Partial<IMasterSpecDetail> = yield select(selectNewMasterSpec)
      const cleanPayload = {
        ...cleanMasterSpec(updatedPayload),
        thumbnailOverrideId: thumbnailImageId || null
      } as Record<string, any>

      const updatedMasterSpec: IMasterSpecDetail = yield call(
        updateMasterSpec,
        cleanPayload
      )
      yield put(masterSpecSliceActions.setDetail(updatedMasterSpec))
    }
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

function* uploadMSpecThumbnailSaga({ data }: any) {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const thumbnailId: string = yield call(uploadMasterSpecThumbnail, data)
    yield put(
      masterSpecSliceActions.updateNewMasterSpecification({
        key: 'thumbnailOverrideId',
        value: get(thumbnailId, 'thumbnailImageId', '')
      })
    )
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}
function* uploadPSpecThumbnailSaga({ data }: any) {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const thumbnailId: string = yield call(uploadMasterSpecThumbnail, data)
    yield put(
      masterSpecSliceActions.updateNewProjectSpecification({
        key: 'thumbnailOverrideId',
        value: get(thumbnailId, 'thumbnailImageId', '')
      })
    )
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* uploadMSpecCharImageSaga({ payload }: any) {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const image = convertImageDataToImage(payload)
    const imageId: string = yield call(uploadCharImage, image)
    yield put(
      masterSpecSliceActions.updateNewCharacteristic({
        key: 'specificationCharacteristicImageId',
        value: get(imageId, 'thumbnailImageId', '')
      })
    )
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* uploadAndUpdateCharSaga({ payload }: any) {
  const resultMasterSpec: Partial<MasterSpecification> = yield select(selectNewMasterSpec)
  const characteristics = get(resultMasterSpec, 'masterSpecificationCharacteristics', [])

  const imageData = payload.specificationCharacteristicImage
  const newCharacteristic = payload

  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const image = convertImageDataToImage(imageData)
    const imageId: Record<string, any> = yield call(uploadCharImage, image)
    newCharacteristic.specificationCharacteristicImageId = imageId.characteristicImageId

    const charId = get(newCharacteristic, 'id', '')
    const resultCharacteristics = replaceObjectById(
      characteristics,
      charId,
      newCharacteristic
    )
    const payloadToClean = {
      ...resultMasterSpec,
      masterSpecificationCharacteristics: resultCharacteristics
    }

    const payload = cleanMasterSpec(payloadToClean)
    if (payload)
      yield put({ type: masterSpecActionType.UPDATE_MASTER_SPEC, payload: payload })
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* uploadAndUpdateProjCharSaga({ payload }: any) {
  const resultProjectSpec: Partial<IProjectSpecDetail> =
    yield select(selectNewProjectSpec)
  const characteristics = get(
    resultProjectSpec,
    'projectSpecificationCharacteristics',
    []
  )

  const imageData = payload.specificationCharacteristicImage
  const newCharacteristic = payload

  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const image = convertImageDataToImage(imageData)
    const imageId: Record<string, any> = yield call(uploadCharImage, image)
    newCharacteristic.specificationCharacteristicImageId = imageId.characteristicImageId

    const charId = get(newCharacteristic, 'id', '')
    const resultCharacteristics = replaceObjectById(
      characteristics,
      charId,
      newCharacteristic
    )
    const payloadToClean = {
      ...resultProjectSpec,
      projectSpecificationCharacteristics: resultCharacteristics
    }

    const payload = cleanProjectSpec(payloadToClean)
    if (payload)
      yield put({ type: masterSpecActionType.UPDATE_PROJECT_SPEC, payload: payload })
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* updateProjectSpecSaga({ payload }: any) {
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const originalProjectData: IProjectSpecDetail = yield select(selectProjectSpecDetail)
    const originalPackage: PackagingDto = yield select(getPackageDetail)

    const hadImage =
      !!get(originalProjectData, 'thumbnailOverride.url') ||
      !!get(originalPackage, 'image.value', '')

    if (
      (!payload.thumbnailOverride || payload.thumbnailOverride.createdAt) &&
      !hadImage
    ) {
      const newProjectSpec: IProjectSpecDetail = yield call(updateProjectSpec, payload)
      yield put(masterSpecSliceActions.setProjectSpecificationDetail(newProjectSpec))
    } else {
      const form_data = convertImageDataToImage(get(payload, 'thumbnailOverride', {}))
      const isImageDeleted = hadImage && !get(payload, 'thumbnailOverride.uid', '')
      let thumbnailImageId: string | null = null

      if (!isImageDeleted) {
        const response: { thumbnailImageId: string } = yield call(
          uploadMasterSpecThumbnail,
          form_data
        )
        thumbnailImageId = response.thumbnailImageId
      }

      const updatedPayload: Partial<IMasterSpecDetail> =
        yield select(selectNewProjectSpec)
      const cleanPayload = {
        ...cleanProjectSpec(updatedPayload),
        thumbnailOverrideId: thumbnailImageId || null
      } as Record<string, any>
      const newProjectSpec: IProjectSpecDetail = yield call(
        updateProjectSpec,
        cleanPayload
      )
      yield put(masterSpecSliceActions.setProjectSpecificationDetail(newProjectSpec))
    }
  } catch (err: any) {
    yield put(masterSpecSliceActions.setAlert(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

type IcheckSapCodesSaga = SagaReturnType<any>
function* checkSapCodesSaga({ payload }: any) {
  const { data, openDuplicateModal, openSendToSapModal } = payload
  yield put(masterSpecSliceActions.setLoader(true))
  const cleanPayload = cleanProjectSpec(data)
  const sapCodes = {
    sapCodes: data.concernedSapCodes.map((c: any) => {
      return c.code
    })
  }
  try {
    const result: IcheckSapCodesSaga = yield call(checkSapCodes, data.id, sapCodes)
    const hasDuplicates = get(result, 'length', false)
    if (hasDuplicates) {
      openDuplicateModal(result, cleanPayload)
    } else {
      openSendToSapModal(cleanPayload, false)
    }
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* sendtoSapSaga({ payload }: any) {
  const { data, associateDuplicatedSapCodes } = payload
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    yield call(updateProjectSpecSaga, { payload: data })
    if (data.status === masterSpecStatus.COMPLETED)
      yield call(sendToSap, data.id, associateDuplicatedSapCodes)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put({
      type: masterSpecActionType.GET_PROJECT_DETAIL,
      payload: { specId: get(payload, 'data.id') }
    })
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* sendNewtoSapSaga({ payload }: any) {
  const { data, associateDuplicatedSapCodes } = payload
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    yield call(createProjectSpec, payload)
    yield call(sendToSap, data.id, associateDuplicatedSapCodes)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* deleteMasterSpecSaga({ payload }: PayloadAction<IDeleteMasterSpecPayload>) {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    yield call(deleteMasterSpec, payload)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* deleteProjectSpecSaga({ payload }: PayloadAction<IDeleteMasterSpecPayload>) {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    yield call(deleteProjectSpec, payload)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* getDetailSaga({ payload }: PayloadAction<IGetDetailPayload>) {
  const { specId } = payload
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const masterSpecDetail: IMasterSpecDetail = yield call(getMasterSpecDetail, specId)
    yield put(masterSpecSliceActions.setDetail(masterSpecDetail))
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* getProjectDetailSaga({ payload }: PayloadAction<IGetDetailPayload>) {
  const { specId, hasLoader = true } = payload
  if (hasLoader) {
    yield put(masterSpecSliceActions.setLoader(true))
  }
  try {
    const projectSpecDetail: IMasterSpecDetail = yield call(getProjectSpecDetail, specId)
    yield put(masterSpecSliceActions.setProjectSpecificationDetail(projectSpecDetail))
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(masterSpecSliceActions.setLoader(false))
    }
  }
}

function* getSpecificationSectionsSaga(): SagaIterator {
  yield put(masterSpecSliceActions.setLoader(true))
  try {
    const specificationSections: IMasterSpecDetail = yield call(getSpecificationSections)
    yield put(masterSpecSliceActions.setSpecificationSections(specificationSections))
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* applyAllSapCodesToCharactersSaga({
  payload
}: PayloadAction<IApplyAllSapCodeActionPayload>) {
  yield put(masterSpecSliceActions.setLoader(true))

  try {
    const projejctSpecDetails: IProjectSpecDetail = yield select(selectProjectSpecDetail)
    const newProjectSpec: IProjectSpecDetail = {
      ...projejctSpecDetails,
      projectSpecificationCharacteristics:
        projejctSpecDetails?.projectSpecificationCharacteristics.map((data) => ({
          ...data,
          sapCodes: [...payload.sapCodes]
        })) || []
    }
    const cleanPayload = cleanProjectSpec(newProjectSpec) as Partial<IProjectSpecDetail>
    yield call(updateProjectSpec, cleanPayload)
    yield put(
      masterSpecActions.getProjectDetail({ specId: newProjectSpec.id, hasLoader: false })
    )
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(masterSpecSliceActions.setLoader(false))
  }
}

function* exportMasterSpecificationSaga({
  payload
}: PayloadAction<IExportMasterSpecificationPayload>) {
  const { id, loader = 'section-master-table' } = payload
  try {
    yield put(appSliceActions.setLoader({ type: loader, value: true }))
    const response: IBlobWithHeadersResp = yield call(exportMasterSpec, id)
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'download.xlsx'

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length > 1) {
        fileName = fileNameMatch[1]
      }
    }

    saveAs(new Blob([response.data], { type: response.data.type }), fileName)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: loader, value: false }))
  }
}

function* exportProjectSpecificationSaga({
  payload
}: PayloadAction<IExportProjectSpecificationPayload>) {
  const { id, loader = 'section-project-table' } = payload
  try {
    yield put(appSliceActions.setLoader({ type: loader, value: true }))
    const response: IBlobWithHeadersResp = yield call(exportProjectSpec, id)
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'download.xlsx'

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length > 1) {
        fileName = fileNameMatch[1]
      }
    }

    saveAs(new Blob([response.data], { type: response.data.type }), fileName)
  } catch (err: any) {
    yield put(masterSpecSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: loader, value: false }))
  }
}

export default function* masterSpecSaga() {
  yield takeLatest(masterSpecActionType.MASTER_SPEC_FETCH_ALL, fetchMasterSpecsSaga)
  yield takeLatest(masterSpecActionType.PROJECT_SPEC_FETCH_ALL, fetchProjectSpecsSaga)
  yield takeLatest(masterSpecActionType.CHARACTERISTICS_FETCH_ALL, getAllCharSaga)
  yield takeLatest(masterSpecActionType.CREATE_MASTER_SPEC, createMasterSpecSaga)
  yield takeLatest(masterSpecActionType.CREATE_PROJECT_SPEC, createProjectSpecSaga)
  yield takeLatest(masterSpecActionType.UPDATE_MASTER_SPEC, updateMasterSpecSaga)
  yield takeLatest(masterSpecActionType.UPLOAD_MSPEC_THUMBNAIL, uploadMSpecThumbnailSaga)
  yield takeLatest(masterSpecActionType.UPLOAD_MSPEC_CHAR_IMAGE, uploadMSpecCharImageSaga)
  yield takeLatest(masterSpecActionType.UPDATE_PROJECT_SPEC, updateProjectSpecSaga)
  yield takeLatest(masterSpecActionType.CHECK_SAP_CODES, checkSapCodesSaga)
  yield takeLatest(masterSpecActionType.UPDATE_PROJECT_SPEC_AND_SEND, sendtoSapSaga)
  yield takeLatest(masterSpecActionType.CREATE_PROJECT_SPEC_AND_SEND, sendNewtoSapSaga)
  yield takeLatest(masterSpecActionType.DELETE_MASTER_SPEC, deleteMasterSpecSaga)
  yield takeLatest(masterSpecActionType.DELETE_PROJECT_SPEC, deleteProjectSpecSaga)
  yield takeLatest(masterSpecActionType.GET_DETAIL, getDetailSaga)
  yield takeLatest(masterSpecActionType.GET_PROJECT_DETAIL, getProjectDetailSaga)
  yield takeLatest(masterSpecActionType.UPLOAD_UPDATE_CHAR, uploadAndUpdateCharSaga)
  yield takeLatest(
    masterSpecActionType.UPLOAD_UPDATE_PROJ_CHAR,
    uploadAndUpdateProjCharSaga
  )
  yield takeLatest(
    masterSpecActionType.GET_SPECIFICATION_SECTIONS,
    getSpecificationSectionsSaga
  )
  yield takeLatest(
    masterSpecActionType.APPLY_SAP_CODE_TO_ALL_CHARACTER,
    applyAllSapCodesToCharactersSaga
  )
  yield takeLatest(
    masterSpecActionType.GET_CHARACTERISTICS_AND_APPLY_DEFAULT,
    getCharAndApplyDefaultSaga
  )
  yield takeLatest(masterSpecActionType.EXPORT_MASTER_SPEC, exportMasterSpecificationSaga)
  yield takeLatest(
    masterSpecActionType.EXPORT_PROJECT_SPEC,
    exportProjectSpecificationSaga
  )
}
