import { get, isEmpty, omit } from 'lodash'

import {
  AZ_DBPRODOTTO_ADMIN,
  AZ_DBPRODOTTO_BU,
  AZ_DBPRODOTTO_CQPKG,
  AZ_DBPRODOTTO_IE,
  AZ_DBPRODOTTO_PACKAGING_ADMIN,
  AZ_DBPRODOTTO_RD,
  AZ_DBPRODOTTO_SALES,
  AZ_DBPRODOTTO_STRATEGICMARKETING,
  AZ_DBPRODOTTO_TCS,
  AZ_WIKIPACK_IE,
  AZ_WIKIPACK_MRKT,
  AZ_WIKIPACK_PKACCEVO,
  AZ_WIKIPACK_PKDEV,
  AZ_WIKIPACK_QC,
  AZ_WIKIPACK_RDF,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_TCS
} from '../../constants/users'

import { PACKAGING_STATUS } from './constants'

export const makeVariantItem = (data) => {
  if (isEmpty(data)) return []

  const level1 = omit(data, ['packageFamilies'])
  const level2 = omit(data.packageFamilies[0], ['packageVariants'])
  return data.packageFamilies[0].packageVariants.reduce(
    (acc, inc) => [
      ...acc,
      {
        ...level1,
        ...level2,
        ...inc,
        ...inc.vialDetails,
        id: inc.id,
        idPackaging: level1.id
      }
    ],
    []
  )
}

export const makePackageVariants = (items) =>
  items.reduce((acc, inc) => {
    const data = makeVariantItem(inc)
    return [...acc, ...data]
  }, [])

const excludedAttachments = {
  salesAttachments: [
    'technical drawing',
    'master specification',
    'project specification',
    'homologation report'
  ],
  buAttachments: ['master specification', 'project specification', 'homologation report'],
  rdAttachments: ['project specification', 'homologation report'],
  cqpkgAttachments: ['homologation report'],
  rdfAttachments: ['technical drawing', 'areadeco', 'why clean'],
  ieAttachments: ['technical drawing', 'areadeco', 'why clean'],
  srcAttachments: ['technical drawing', 'areadeco', 'why clean'],
  acEvAttachments: ['areadeco', 'why clean'],
  pkDevAttachments: [
    'msds',
    'tds',
    'image',
    'stability',
    'compatibility',
    'country check',
    'challenge test',
    'industrialization test report'
  ],
  defaultAttachments: []
}

const refactorAttachments = (attachments = []) => {
  if (!attachments.length) return attachments
  const newAttachment = attachments.map(({ contentTypeId, id, ...attach }) => {
    delete attach.contentType
    return {
      ...attach,
      name: get(attach, 'file.name', ''),
      formFieldName: id,
      attachmentContentTypeId: contentTypeId
    }
  })
  return newAttachment
}

const refactorVialVariants = (vialVariants) => {
  const refactoredList = vialVariants.reduce((acc, { attachments, vialDetails }) => {
    const vialDetailCopy = { ...vialDetails }
    if (vialDetailCopy.id) {
      delete vialDetailCopy.id
    }
    acc.push({
      vialDetails: vialDetailCopy,
      attachments: refactorAttachments(attachments)
    })
    return acc
  }, [])
  return refactoredList
}

export const prepareUpdatePackageData = (newPackage, author, isMulti = false) => {
  const imageStoreKey = get(newPackage, 'image.value', '')

  const relatedPackagesIds = get(newPackage, 'relatedPackages', []).map(({ id }) => id)
  const furtherVersionsIds = get(newPackage, 'furtherVersions', []).map(({ id }) => id)

  const expiryDate = get(newPackage, 'expiryDate', null)

  const families = get(newPackage, 'packageFamilies', []).map((singleFamily) => {
    const newPackage = {
      ...singleFamily
    }
    if (newPackage.sideCollectorId) {
      delete newPackage.sideCollectorId
    }

    const newPackageVariants = get(newPackage, 'packageVariants', []).map(
      (variantData) => {
        const vialDetailCopy = get(variantData, 'vialDetails', {})
        const newVariant = {
          ...variantData,
          vialDetails: !isEmpty(vialDetailCopy)
            ? { ...vialDetailCopy, bottle: undefined }
            : null
        }
        return newVariant
      }
    )

    return {
      ...newPackage,
      size: get(newPackage, 'size', null),
      panNumber: get(newPackage, 'panNumber', null),
      tipShape: get(newPackage, 'tipShape', null),
      panShape: get(newPackage, 'panShape', null),
      shape: get(newPackage, 'shape', null),
      technology: get(newPackage, 'technology', null),
      capacityRange: get(newPackage, 'capacityRange', null),
      panSize: get(newPackage, 'panSize', null),
      applicatorAndAccessories: get(newPackage, 'applicatorAndAccessories', null),
      closure: get(newPackage, 'closure', null),
      material: get(newPackage, 'material', null),
      level: get(newPackage, 'level', null),
      scouting: get(newPackage, 'scouting', null),
      mirror: get(newPackage, 'mirror', null),
      airtight: get(newPackage, 'airtight', null),
      clean: get(newPackage, 'clean', null),
      cleanTypes: get(newPackage, 'cleanTypes', null),
      freeOfMaterial: get(newPackage, 'freeOfMaterial', null),
      MP54: get(newPackage, 'MP54', null),
      foodgrade: get(newPackage, 'foodgrade', null),
      packageVariants: newPackageVariants,
      alternativeStandards: get(newPackage, 'alternativeStandards', []).map(
        ({ id }) => id
      )
    }
  })

  // NOTE - possible improve, at the packaging forms move category and subcategory to packageFamilies
  // so this portion of code is unnecessary
  if (!isMulti) {
    families[0] = {
      ...families[0],
      category: get(newPackage, 'category', ''),
      subcategory: get(newPackage, 'subcategory', '')
    }
  }
  // =================================================

  return {
    image: get(window, `tmpFiles.${imageStoreKey}`, null),
    document: {
      author,
      idDbPackage: get(newPackage, 'idDbPackage', ''),
      packagingCode: get(newPackage, 'packagingCode', ''),
      isValid: get(newPackage, 'isValid', true),
      isValidNote: get(newPackage, 'isValidNote', ''),
      supplierRef: get(newPackage, 'supplierRef', null),
      testedSupplierId: get(newPackage, 'testedSupplier.id', null),
      roomAllocation: get(newPackage, 'roomAllocation', null),
      expiryDate: expiryDate && new Date(expiryDate).toISOString(),
      relatedPackages: relatedPackagesIds,
      furtherVersions: furtherVersionsIds,
      status: get(newPackage, 'status', PACKAGING_STATUS.DRAFT),
      leadTimeDays: +get(newPackage, 'leadTimeDays', null) || null,
      packageFamilies: families,

      // target price section
      MOQ: undefined,
      USDTargetPrice: undefined,
      targetPrice: +get(newPackage, 'targetPrice', null),
      currencyCode: get(newPackage, 'currencyCode', null),
      countryOfOriginCode: get(newPackage, 'countryOfOriginCode', null),
      priceNotes: get(newPackage, 'priceNotes', null),
      validityFrom: get(newPackage, 'validityFrom', null),
      validityTo: get(newPackage, 'validityTo', null)
    }
  }
}

export const prepareMonoPackageData = (newPackage, author) => {
  const isVial = get(newPackage, 'category', '') === 'Vials'

  const vialVariants = get(newPackage, 'packageFamilies[0].packageVariants', [])
  const noVialAttachments = get(newPackage, 'filteredAttachment', [])

  const packageFamilies = { ...get(newPackage, 'packageFamilies[0]', {}) }

  if (packageFamilies.sideCollectorId) {
    delete packageFamilies['sideCollectorId']
  }
  if (packageFamilies.id) {
    delete packageFamilies['id']
  }
  let attachData
  if (!isVial) {
    attachData = noVialAttachments.length
      ? [
          {
            attachments: refactorAttachments(noVialAttachments)
          }
        ]
      : [{}]
  } else {
    const refactoredVariants = refactorVialVariants(vialVariants)
    attachData = refactoredVariants
  }

  const imageStoreKey = get(newPackage, 'image.value', '')

  const relatedPackagesIds = get(newPackage, 'relatedPackages', []).map(({ id }) => id)
  const furtherVersionsIds = get(newPackage, 'furtherVersions', []).map(({ id }) => id)

  const expiryDate = get(newPackage, 'expiryDate', null)

  const result = {
    image: get(window, `tmpFiles.${imageStoreKey}`, null),
    document: {
      type: 'MONO',
      author,
      idDbPackage: 0,
      isValid: get(newPackage, 'isValid', true),
      isValidNote: get(newPackage, 'isValidNote', ''),
      supplierRef: get(newPackage, 'supplierRef', null),
      testedSupplierId: get(newPackage, 'testedSupplier.id', null),
      roomAllocation: get(newPackage, 'roomAllocation', null),
      expiryDate: expiryDate && new Date(expiryDate).toISOString(),
      relatedPackages: relatedPackagesIds,
      furtherVersions: furtherVersionsIds,
      status: get(newPackage, 'status', PACKAGING_STATUS.DRAFT),
      targetPrice: +get(newPackage, 'targetPrice', undefined) || undefined,
      leadTimeDays: +get(newPackage, 'leadTimeDays', null) || null,
      MOQ: +get(newPackage, 'MOQ', undefined) || undefined,
      packageFamilies: [
        {
          ...packageFamilies,
          category: get(newPackage, 'category', ''),
          subcategory: get(newPackage, 'subcategory', ''),
          packageVariants: attachData
        }
      ]
    }
  }
  return result
}

export const prepareMultiPackageData = (newPackage, author) => {
  const filteredAttachment = get(newPackage, 'filteredAttachment', [])

  const attachments = filteredAttachment.length
    ? refactorAttachments(filteredAttachment)
    : []

  const imageStoreKey = get(newPackage, 'image.value', '')

  const relatedPackagesIds = get(newPackage, 'relatedPackages', []).map(({ id }) => id)
  const furtherVersionsIds = get(newPackage, 'furtherVersions', []).map(({ id }) => id)

  const expiryDate = get(newPackage, 'expiryDate', null)

  const families = get(newPackage, 'packageFamilies', []).map((family) => {
    const familyCopy = { ...family }
    const category = get(familyCopy, 'category', '')
    const isVial = category === 'Vials'

    const vialVariants = get(familyCopy, 'packageVariants', [])
    const alternativeStandards = get(familyCopy, 'alternativeStandards', []).map(
      ({ id }) => id
    )
    let attachData = []
    if (isVial) {
      attachData = refactorVialVariants(vialVariants)
    }

    if (familyCopy.sideCollectorId) {
      delete familyCopy['sideCollectorId']
    }
    if (familyCopy.id) {
      delete familyCopy['id']
    }
    const newPackage = {
      ...familyCopy,
      alternativeStandards
    }

    if (attachData) {
      newPackage['packageVariants'] = attachData
    }

    return newPackage
  })

  return {
    image: get(window, `tmpFiles.${imageStoreKey}`, null),
    document: {
      type: 'MULTI',
      author,
      idDbPackage: 0,
      isValid: get(newPackage, 'isValid', true),
      isValidNote: get(newPackage, 'isValidNote', ''),
      supplierRef: get(newPackage, 'supplierRef', null),
      testedSupplierId: get(newPackage, 'testedSupplier.id', null),
      roomAllocation: get(newPackage, 'roomAllocation', null),
      expiryDate: expiryDate && new Date(expiryDate).toISOString(),
      relatedPackages: relatedPackagesIds,
      furtherVersions: furtherVersionsIds,
      status: get(newPackage, 'status', PACKAGING_STATUS.DRAFT),
      leadTimeDays: +get(newPackage, 'leadTimeDays', null) || null,
      packageFamilies: families,
      attachments
    }
  }
}

export const getRoleAttachments = (role) => {
  switch (true) {
    case role.includes(AZ_DBPRODOTTO_SALES) ||
      role.includes(AZ_DBPRODOTTO_STRATEGICMARKETING) ||
      role.includes(AZ_DBPRODOTTO_BU) ||
      role.includes(AZ_DBPRODOTTO_TCS):
      return 'salesAttachments'
    // NOTE - at the moment there is no buAttachments condition
    // case role.includes('AZ.Dbprodotto-TCS'):
    //   return 'buAttachments'
    case role.includes(AZ_DBPRODOTTO_RD) || role.includes(AZ_DBPRODOTTO_IE):
      return 'rdAttachments'
    case role.includes(AZ_DBPRODOTTO_CQPKG):
      return 'cqpkgAttachments'
    case role.includes(AZ_WIKIPACK_RDF):
      return 'rdfAttachments'
    case role.includes(AZ_WIKIPACK_IE):
      return 'ieAttachments'
    case role.includes(AZ_WIKIPACK_SRC):
      return 'srcAttachments'
    case role.includes(AZ_WIKIPACK_PKACCEVO):
      return 'acEvAttachments'
    case role.includes(AZ_WIKIPACK_PKDEV) || role.includes(AZ_DBPRODOTTO_PACKAGING_ADMIN):
      return 'pkDevAttachments'
    default:
      return 'defaultAttachments'
  }
}

// ora per la visualizzazione degli attachment in base ai ruoli si utilizza un oggetto mappa
// per ogni tipologia sappiamo quali utenti possono vederlo
// verifichiamo se l'utente ha uno di quei ruoli
export const attachmentByRole = {
  'technical drawing': [
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_PKDEV,
    AZ_WIKIPACK_SRC,
    AZ_WIKIPACK_QC,
    AZ_WIKIPACK_IE,
    AZ_DBPRODOTTO_ADMIN
  ],
  areadeco: [
    AZ_WIKIPACK_PKACCEVO,
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_PKDEV,
    AZ_WIKIPACK_QC,
    AZ_WIKIPACK_SRC,
    AZ_WIKIPACK_TCS,
    AZ_WIKIPACK_IE,
    AZ_WIKIPACK_MRKT,
    AZ_DBPRODOTTO_ADMIN
  ],
  'why clean': [
    AZ_WIKIPACK_PKACCEVO,
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_PKDEV,
    AZ_WIKIPACK_QC,
    AZ_WIKIPACK_SRC,
    AZ_WIKIPACK_IE,
    AZ_WIKIPACK_MRKT,
    AZ_DBPRODOTTO_ADMIN
  ],
  'master specification': [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_QC, AZ_DBPRODOTTO_ADMIN],
  'project specification': [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_QC, AZ_DBPRODOTTO_ADMIN],
  regulatory: [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_TCS, AZ_DBPRODOTTO_ADMIN],
  other: [AZ_WIKIPACK_PKDEV, AZ_DBPRODOTTO_ADMIN],
  'homologation report': [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_QC, AZ_DBPRODOTTO_ADMIN],
  'Tested Claim': [
    AZ_WIKIPACK_PKACCEVO,
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_PKDEV,
    AZ_WIKIPACK_QC,
    AZ_WIKIPACK_SRC,
    AZ_WIKIPACK_TCS,
    AZ_WIKIPACK_IE,
    AZ_WIKIPACK_MRKT,
    AZ_DBPRODOTTO_ADMIN
  ]
}

// ora per la visualizzazione degli status in base ai ruoli si utilizza un oggetto mappa
// per ogni status sappiamo quali utenti possono vederlo
// verifichiamo se l'utente ha uno di quei ruoli
export const statusesByRole = {
  AVAILABLE: [
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_PKDEV,
    AZ_WIKIPACK_SRC,
    AZ_WIKIPACK_QC,
    AZ_WIKIPACK_IE,
    AZ_WIKIPACK_MRKT,
    AZ_WIKIPACK_TCS,
    AZ_DBPRODOTTO_ADMIN,
    AZ_WIKIPACK_PKACCEVO
  ],
  DRAFT: [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_SRC, AZ_DBPRODOTTO_ADMIN],
  DISCONTINUED: [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_SRC, AZ_DBPRODOTTO_ADMIN],
  HOMOLOGATED: [
    AZ_WIKIPACK_PKDEV,
    AZ_WIKIPACK_SRC,
    AZ_WIKIPACK_RDF,
    AZ_WIKIPACK_IE,
    AZ_DBPRODOTTO_ADMIN
  ]
}

export const filterAttachmentsByRole = (role, attachmentContentType) => {
  const contTypeName = attachmentContentType.name.toLowerCase()
  if (
    role === 'salesAttachments' ||
    role === 'acEvAttachments' ||
    role === 'ieAttachments' ||
    role === 'srcAttachments' ||
    role === 'rdfAttachments'
  ) {
    return selectedAttachment[role].includes(contTypeName)
  }
  return !excludedAttachments[role].includes(contTypeName)
}

export const filterAttachments = (role, list) => {
  if (role && list) {
    return list.filter(({ attachmentContentType }) => {
      return filterAttachmentsByRole(role, attachmentContentType)
    })
  }
}

const selectedAttachment = {
  salesAttachments: ['areadeco'],
  rdfAttachments: ['technical drawing', 'areadeco', 'why clean'],
  ieAttachments: ['technical drawing', 'areadeco', 'why clean'],
  srcAttachments: ['technical drawing', 'areadeco', 'why clean'],
  acEvAttachments: ['areadeco', 'why clean']
}
