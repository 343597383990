import { FC, useEffect, useState } from 'react'

import { Form } from 'antd'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { canUserDeleteVariant } from '@/utils/permissionsHelpers'

import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, InfoCell, SectionTitle } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { Title } from '../../components/Title'
import { PageContent } from '../../containers'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import { PACKAGE_TYPE } from '../../features/packaging/model'
import {
  getPackageDetailName,
  getPackageDetailVariant,
  selectAllTaxonomies,
  selectPackagingError
} from '../../features/packaging/selectors'
import { selectVariantEditing } from '../../features/packaging/selectors'
import { getPackageDetail } from '../../features/packaging/selectors'
import { getIsLoading } from '../../features/packaging/selectors'
import { selectPermissions, selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { checkFieldVisibilityByRoles, getDynamicPackDropdownByKey } from '../../utils'
import { orderAttachmentsByContentType } from '../../utils/attachmentsHelper'
import { AppRoutes } from '../constants'

import AttachmentInVariant from './AttachmentInVariant'
import DeleteButton from './DeleteButton'
import SaveButton from './SaveButton'
import { ButtonWrapper } from './styled'

const VariantDetail: FC = () => {
  const dispatch = useDispatch()
  const { idPackaging, id } = useParams()
  const packagingError = useSelector(selectPackagingError)
  const userRoles = useSelector(selectUserRole)
  const permissions = useSelector(selectPermissions)
  const isLoading = useSelector(getIsLoading)
  const variantData = useSelector(getPackageDetailVariant)
  const packagingData = useSelector(getPackageDetail)
  const allTaxonomies = useSelector(selectAllTaxonomies)
  const isMulti = get(packagingData, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
  const tempVariant = useSelector(selectVariantEditing)
  const canReadMasterSpec = permissions.wikipackMaster?.canRead || false

  const packagingCode = useSelector(getPackageDetailName)
  const url = `${window.location.href}`

  const getDropDown = (key: string) => {
    const relatedPackage = get(variantData, 'packageFamily', {})
    const subcategory = get(relatedPackage, 'subcategory', '')
    return getDynamicPackDropdownByKey({
      key,
      category: 'vials',
      subcategory,
      taxonomies: allTaxonomies
    })
  }

  useEffect(() => {
    if (id && idPackaging) {
      dispatch(packagingActions.getAttachmenttypes())
      dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
      dispatch(
        packagingActions.getVariantDetail({ variantId: id, packagingId: idPackaging })
      )
      dispatch(packagingActions.getTestedSupplier())
    }
    return () => {
      dispatch(packagingSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isOnEdit, setIsOnEdit] = useState(false)

  const data = isOnEdit ? tempVariant : variantData

  const vialDetails = get(data, 'vialDetails', '')
  const variantCode = get(data, 'variantCode', '')

  const isEditable = permissions.wikipackVariant?.canUpdate

  const checkFieldVisibility = (key: string) => {
    return checkFieldVisibilityByRoles({
      key,
      isOnCreate: false,
      isOnEdit,
      isOnRead: !isOnEdit,
      roles: userRoles
    })
  }

  const updateHandler = (key: string, value: any) => {
    dispatch(packagingSliceActions.updateTempVariant({ key, value }))
  }

  const updateVialDetail = (key: string, value: any) => {
    const newVialDetails = {
      ...vialDetails,
      [key]: value
    }
    updateHandler('vialDetails', newVialDetails)
  }

  const orderedAttachments = orderAttachmentsByContentType(
    get(variantData, 'attachments', [])
  )

  const ActionsTop = isEditable && (
    <ButtonWrapper>
      {canReadMasterSpec && (
        <>
          <Button
            label="Master Specifications"
            onClick={() => window.open(`${url}/master-specifications`)}
          />
          <Button
            label="Project Specifications"
            onClick={() => window.open(`${url}/project-specifications`)}
          />
        </>
      )}
      {!isOnEdit ? (
        <Button
          label="Edit"
          onClick={() => {
            setIsOnEdit(!isOnEdit)
            dispatch(packagingSliceActions.editVariant())
          }}
        />
      ) : (
        <>
          {canUserDeleteVariant({ userRoles }) && <DeleteButton id={id!} />}
          <Button
            label="Cancel"
            variant="ghost"
            onClick={() => {
              setIsOnEdit(!isOnEdit)
            }}
          />
          <SaveButton
            onConfirm={() => {
              setIsOnEdit(false)
              dispatch(
                packagingActions.updateVariant({
                  packagingId: idPackaging as string
                })
              )
            }}
          />
        </>
      )}
    </ButtonWrapper>
  )

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Breadcrumb
          items={[
            {
              name: 'Packaging',
              href: AppRoutes.WIKIPACK_PACKAGING
            },
            {
              name: packagingCode || 'Package',
              href: `${AppRoutes.WIKIPACK_PACKAGING}/${idPackaging}`
            },
            {
              name: `variant ${variantCode}`
            }
          ]}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Title title={`Variant ${variantCode}`} variantTitle="h2" />
            <SectionTitle
              value={isOnEdit ? 'Information' : 'Variant Details'}
              isBold
              addon={ActionsTop}
            />
            <Divider />
            <BoxWrapper enablePadding>
              <InfoCell label={'Variant Code'} value={variantCode} />
              {isOnEdit ? (
                <>
                  <InfoCell
                    label={'Created At'}
                    value={get(data, 'createdAt', '')}
                    dataType="date"
                  />
                  {checkFieldVisibility('variantSupplierCode') && (
                    <InfoCell
                      label={'Variant Supplier Code'}
                      value={get(vialDetails, 'variantSupplierCode', '')}
                      isEditing
                      inputConfig={{
                        onChange: (value: any) =>
                          updateVialDetail('variantSupplierCode', value)
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {checkFieldVisibility('variantSupplierCode') && (
                    <InfoCell
                      label={'Variant Supplier Code'}
                      value={get(vialDetails, 'variantSupplierCode', '')}
                    />
                  )}
                  <InfoCell
                    label={'Created At'}
                    value={get(data, 'createdAt', '')}
                    dataType="date"
                  />
                </>
              )}
            </BoxWrapper>
            <Divider disableBorder />
            {!isOnEdit && <SectionTitle value="Vial Details" />}
            <Divider />
            <Form layout="vertical">
              <BoxWrapper enablePadding>
                <InfoCell value={get(vialDetails, 'bottle', '')} label={'Bottle'} />
                <InfoCell
                  value={get(vialDetails, 'dipstick', '')}
                  label={'Stem'}
                  isEditing={isOnEdit}
                  inputType="select"
                  inputConfig={{
                    items: getDropDown('dipstick'),
                    onChange: ({ value }: any) => updateVialDetail('dipstick', value)
                  }}
                />
                <InfoCell
                  value={get(vialDetails, 'wiper', '')}
                  label={'Wiper'}
                  isEditing={isOnEdit}
                  inputType="select"
                  inputConfig={{
                    items: getDropDown('wiper'),
                    onChange: ({ value }: any) => updateVialDetail('wiper', value)
                  }}
                />
                <InfoCell
                  value={get(vialDetails, 'applicator', '')}
                  label={'Applicator'}
                  isEditing={isOnEdit}
                  inputType="select"
                  inputConfig={{
                    items: getDropDown('applicator'),
                    onChange: ({ value }: any) => updateVialDetail('applicator', value)
                  }}
                />
                <InfoCell
                  value={get(vialDetails, 'applicatorNumber', '')}
                  label={'Applicator Number'}
                  isEditing={isOnEdit}
                  inputConfig={{
                    onChange: (value: any) => updateVialDetail('applicatorNumber', value)
                  }}
                />
              </BoxWrapper>
              <Divider disableBorder />
            </Form>

            {!isMulti && (
              <AttachmentInVariant
                isOnEdit={isOnEdit}
                list={orderedAttachments}
                variantId={id!}
                packageId={idPackaging!}
              />
            )}
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default VariantDetail
